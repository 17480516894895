import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { routeCodes } from "../../config/routes";
import { getString } from "../../utilities";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { getLangCode, language } from "../../config";

export default function Instructions({ isFree, children }) {
  const navigate = useNavigate();

  if (isFree) {
    return (
      <>
        <div className="instructions">{children}</div>

        <div className="instructions">
          {getString("tools.upgrade")}
          &nbsp;&nbsp;
          <button
            type="button"
            onClick={() => {
              navigate(routeCodes.MANAGE);
            }}
            className="button-flat-color pt-cyan round auto"
          >
            {getString(getLangCode() === language.la ? "upgrade.title.3" : "upgrade.title.1")}
            <Arrow className="inline-arrow" />
          </button>
        </div>
      </>
    );
  }

  return <div className="instructions">{children}</div>;
}

Instructions.propTypes = {
  isFree: PropTypes.bool.isRequired,
  children: PropTypes.any,
};

Instructions.defaultProps = {
  children: undefined,
};
