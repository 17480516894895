import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useMutation } from "urql";
import { useLocation } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormProvider, useForm } from "react-hook-form";

import { MANAGE_ACCOUNT_MUTATION } from "../../graphql/mutations";
import { getString, handlePostRequest, isAFunction } from "../../utilities";
import { gtmSubscribed } from "../../utilities/gtm";
import { subscribeSubmit } from "../../utilities/subscribe";
import { userRoles, recaptchaSiteKey, tokenName, getLangCode, language, getParamValue } from "../../config";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import SubscribeConfirm from "../global/subscribeConfirm";
import NotAppliedModal from "../global/notAppliedModal";
import PasswordConfirmModal from "../global/passwordConfirmModal";
import PlanButton from "./planButton";
import Features from "../subscribe/features";
import PaymentFields from "../subscribe/paymentFields";
import FamilyPlans from "./familyPlans";
import ClassroomPlans from "./classroomPlans";
import SchoolPlans from "./schoolPlans";
import StudentFields from "../global/studentFields";
import { planIdSelector, roleSelector } from "../../redux/slices/loginSlice";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function ManageSubscriptionForm({
  accountBalance,
  plan,
  setPlan,
  planData,
  isPremiumWeb,
  isUpdating,
  setIsUpdating,
  success,
  accountInfo,
  setOriginalPlan,
  setOriginalAmount,
  promo,
  setPromo,
  queryErrors,
  isLegacy,
}) {
  const location = useLocation();
  const loginRole = useSelector(roleSelector);
  const planId = useSelector(planIdSelector);

  const [planAmount, setPlanAmount] = useState(null);
  const [role, setRole] = useState(loginRole);
  const [total, setTotal] = useState(0);
  const [hasPromoNotApplied, setHasPromoNotApplied] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardNotApplied, setGiftCardNotApplied] = useState(false);
  const [notAppliedModal, setNotAppliedModal] = useState({ show: false });
  const [confirmModal, setConfirmModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [creditComplete, setCreditComplete] = useState(false);

  const isFree = loginRole === userRoles.free;
  const planChosen = !!plan;

  const showForm = (isFree || accountInfo?.canceled || isLegacy) && planChosen;

  let finalAmount = planAmount;

  if (promo && planAmount) {
    if (promo.amountOff) {
      finalAmount = planAmount - promo.amountOff;
      if (finalAmount < 0) finalAmount = 0;
    }

    if (promo.percentOff) {
      finalAmount = planAmount - planAmount * promo.percentOff;
    }
  }

  const balanceGteAmount = accountBalance >= finalAmount;

  const freeCanUpdate = !isUpdating && showForm && (giftCards.length > 0 || creditComplete || balanceGteAmount);

  const premiumCanUpdate =
    !isUpdating &&
    ((planChosen && !accountInfo?.canceled && planData?.[role]?.[plan]?.planId !== planId) ||
      (planChosen && accountInfo?.canceled));

  const canUpdate = isFree ? freeCanUpdate : premiumCanUpdate;

  const creditNotRequired =
    (planChosen && finalAmount < total) || (!isFree && accountInfo?.sourceExists) || balanceGteAmount;

  const formActionString = "Manage_Subscription_Payment_Form_Submission";

  const { getValues, handleSubmit, register, watch, reset, trigger, formState } = useForm();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setRole(loginRole);
  }, [loginRole]);

  const onUpdateCompleted = (data) => {
    const upgradeRoleTitle = {
      FAMILY: getString(`subscribe.plans.family.title.4`),
      CLASSROOM: getString(`subscribe.plans.classroom.title.4`),
      SCHOOL: getString(`subscribe.plans.school.title.3`),
    };

    // default to free user upgrading message
    let successMessage = (
      <>
        <p>
          <strong>
            {getString("manage.subscription.success.free.upgraded", { replace: [upgradeRoleTitle[role]] })}
          </strong>
        </p>
        <p>{getString("manage.subscription.success.free.instructions")}</p>
      </>
    );
    if (isPremiumWeb) {
      successMessage = <p>{getString("manage.subscription.success.premium", { replace: [upgradeRoleTitle[role]] })}</p>;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      cardElement.clear();
    }

    gtmSubscribed(planData[role][plan], { invoiceId: data?.updateUser?.invoiceId });
    const {
      updateUser: {
        subscription: { planId, billingPlatform },
        token,
      },
    } = data;
    try {
      window.localStorage.setItem("planId", planId);
      window.localStorage.setItem("billingPlatform", billingPlatform);
    } catch (e) {}
    if (isPremiumWeb) {
      try {
        window.localStorage.setItem(tokenName, token);
      } catch (e) {}
    }
    success({ message: successMessage, reload: true, ...(isPremiumWeb && { token }) });
  };

  const onError = (error) => {
    setSubmitErrors(error);
    setIsUpdating(false);
  };

  const [result, updateUser] = useMutation(MANAGE_ACCOUNT_MUTATION);
  const { fetching: mutating } = result;

  const onRecaptchaCompleted = async (data) => {
    const myVariables = {
      billingPlan: planData[role][plan].planId,
      giftCards,
    };

    const formValues = getValues();
    if (formValues.accessName) {
      myVariables.accessName = formValues.accessName;
    }

    if (formValues.accessCode) {
      myVariables.accessCode = formValues.accessCode;
    }

    if (promo && promo.shortname) {
      myVariables.promoCode = promo.shortname;
    }

    await subscribeSubmit({
      data,
      setSubmitErrors,
      cardElement: elements.getElement(CardElement),
      mutation: (variables) =>
        updateUser(variables).then((result) => handlePostRequest(result, onUpdateCompleted, onError)),
      creditNotRequired: true,
      setSubmitting: setIsUpdating,
      plan,
      myVariables,
      creditComplete,
      action: formActionString,
      stripe,
    });
  };

  const { executeQuery: recaptchaVerify, loading: verifying } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onError,
  );

  const loading = mutating || verifying;
  const submitForm = (creditNotRequired) => {
    setIsUpdating(true);

    // execute recaptcha to get token
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(recaptchaSiteKey, { action: formActionString }).then((token) => {
        // if token call api query to validate token with Google
        recaptchaVerify({ token, action: formActionString, verify: true });
      });
    });
  };

  const planSelectFunc = (roleString) => {
    let newPromo = null;

    if (promo && promo.planGroups.includes(roleString.toUpperCase())) {
      newPromo = promo;
    }

    setRole(roleString);
    setPlan(null);
    setPlanAmount(null);
    setOriginalPlan(null);
    setOriginalAmount(null);
    setGiftCards([]);
    setTotal(0);
    setPromo(newPromo);
  };

  useEffect(() => {
    if (planData) {
      // automate plan selection if valid query param
      const subscriptionParam = getParamValue("subscription", { location });
      const familyString = getString("family").toLowerCase();
      const classroomString = getString("classroom").toLowerCase();
      if ([familyString, classroomString].includes(subscriptionParam)) {
        const subscription = "m12";
        let data = planData.FAMILY;
        let role = userRoles.family;

        if (subscriptionParam === classroomString) {
          data = planData.CLASSROOM;
          role = userRoles.classroom;
        }
        planSelectFunc(role);
        setPlanAmount(data[subscription].amount);
        setPlan(subscription);
      }
    }
  }, [planData]);

  const renderForm = () => {
    const subPlans = {
      FAMILY: (
        <FamilyPlans
          plan={plan}
          setPlan={setPlan}
          setPlanAmount={setPlanAmount}
          planData={planData.FAMILY}
          promo={promo}
        />
      ),
      CLASSROOM: (
        <ClassroomPlans plan={plan} setPlan={setPlan} setPlanAmount={setPlanAmount} planData={planData.CLASSROOM} />
      ),
      SCHOOL: <SchoolPlans plan={plan} setPlan={setPlan} setPlanAmount={setPlanAmount} planData={planData.SCHOOL} />,
    };

    let confirmTitle = "";

    if (role && role !== userRoles.free) {
      confirmTitle = getString(`subscribe.plans.${role.toLowerCase()}.title.${role === userRoles.school ? 1 : 2}`);
    }

    const isStudentPlan = [userRoles.classroom, userRoles.school].includes(role);

    return (
      <>
        {subPlans[role] || null}

        <form
          onSubmit={handleSubmit(() => {
            if (giftCardNotApplied) {
              // there is a giftcard field filled out but not applied
              // modal call to action to apply giftcard
              setNotAppliedModal({ show: true, type: "giftCard" });
            } else if (hasPromoNotApplied) {
              // the promo code field is filled out but not applied
              // modal call to action to apply promo
              setNotAppliedModal({ show: true, type: "promo" });
            } else if (isFree) {
              // subscribe confirmation modal
              setConfirmModal(true);
            } else {
              setPasswordModal(true);
            }
          })}
        >
          <div className="container-small">
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <OptionallyDisplayed doDisplay={showForm}>
              <FormProvider
                getValues={getValues}
                register={register}
                trigger={trigger}
                watch={watch}
                reset={reset}
                formState={formState}
              >
                <OptionallyDisplayed doDisplay={isStudentPlan}>
                  <StudentFields submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} />
                </OptionallyDisplayed>
                <PaymentFields
                  total={total}
                  setTotal={setTotal}
                  setGiftCardNotApplied={setGiftCardNotApplied}
                  giftCards={giftCards}
                  setGiftCards={setGiftCards}
                  hasPromoNotApplied={hasPromoNotApplied}
                  setHasPromoNotApplied={setHasPromoNotApplied}
                  doesNotRequireCredit={creditNotRequired}
                  promo={promo}
                  setPromo={setPromo}
                  planGroup={role}
                  setCreditComplete={setCreditComplete}
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                />
              </FormProvider>
            </OptionallyDisplayed>

            <div className="manage-form-footer">
              <button type="submit" className="button-flat-color pt-green manage-update-button" disabled={!canUpdate}>
                {getString("update.0")}
              </button>

              <OptionallyDisplayed doDisplay={showForm}>
                <div data-testid="subscribe-terms-id" className="subscribe-terms">
                  <p>{getString("subscribe.terms", { replace: [getString("update.3")], html: true })}</p>
                  <p>{getString("recaptcha", { html: true })}</p>
                </div>
              </OptionallyDisplayed>
            </div>
          </div>
        </form>

        <SubscribeConfirm
          doShow={confirmModal}
          submit={() => {
            setConfirmModal((prev) => !prev);
            submitForm(canUpdate && creditNotRequired);
          }}
          cancel={() => {
            setConfirmModal((prev) => !prev);
          }}
          planData={{
            title: confirmTitle,
            plan,
            amount: planAmount,
            finalAmount,
            promo,
            creditNotRequired,
            giftCards: { cards: giftCards, total },
          }}
        />

        <NotAppliedModal
          doShow={notAppliedModal.show}
          type={notAppliedModal.type || "giftCard"}
          close={() => {
            setNotAppliedModal({ notAppliedModal: { show: false } });
          }}
        />

        <PasswordConfirmModal
          doShow={!!passwordModal}
          close={() => {
            setPasswordModal((prev) => !prev);
          }}
          submit={() => {
            if (!isUpdating) {
              setPasswordModal((prev) => !prev);
              submitForm(canUpdate && creditNotRequired);
            }
          }}
        >
          <p>{getString("manage.password")}</p>
        </PasswordConfirmModal>

        {(loading || isUpdating) && (
          <LoadingAnim position="fixed" className="background-transparent-white">
            <h4>{getString("update.1")}</h4>
          </LoadingAnim>
        )}
      </>
    );
  };

  if (queryErrors) {
    return (
      <div className="container-small">
        <ErrorMessage error={queryErrors} />
      </div>
    );
  }
  if (planData) {
    return (
      <>
        <OptionallyDisplayed doDisplay={isFree}>
          {/* plan selection only available to free/public subscribers */}
          <div className="cta">{getString("subscribe.cta")}</div>
          <div className="planselect">
            <PlanButton plan={role} pRole={userRoles.family} color="pt-cyan" func={planSelectFunc} promo={promo} />
            <PlanButton plan={role} pRole={userRoles.classroom} color="pt-blue" func={planSelectFunc} promo={promo} />
            {getLangCode() === language.default && (
              <PlanButton plan={role} pRole={userRoles.school} color="school-sub" func={planSelectFunc} promo={promo} />
            )}
          </div>
        </OptionallyDisplayed>

        {renderForm()}

        <OptionallyDisplayed doDisplay={isFree}>
          {/* plan selection only available to free/public subscribers */}
          <Features familyData={planData.FAMILY} classroomData={planData.CLASSROOM} promo={promo} />
        </OptionallyDisplayed>
      </>
    );
  }

  return <LoadingAnim />;
}

ManageSubscriptionForm.propTypes = {
  accountBalance: PropTypes.number.isRequired,
  plan: PropTypes.string,
  setPlan: PropTypes.func.isRequired,
  planData: PropTypes.object,
  isPremiumWeb: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  accountInfo: PropTypes.object,
  setOriginalPlan: PropTypes.func.isRequired,
  setOriginalAmount: PropTypes.func.isRequired,
  promo: PropTypes.object,
  setPromo: PropTypes.func.isRequired,
  queryErrors: PropTypes.any,
  isLegacy: PropTypes.bool,
};

ManageSubscriptionForm.defaultProps = {
  plan: undefined,
  planData: undefined,
  accountInfo: undefined,
  promo: undefined,
  queryErrors: undefined,
  isLegacy: false,
};
