import React from "react";
import PropTypes from "prop-types";
import Modal from "../global/modal";
import { getString, login, removeTrailingSlash } from "../../utilities";
import { routeCodes } from "../../config/routes";
import { getSearchParamString } from "../../config";

export default function SubscribedModal({ token, accessName, accessCode, isComplete, isStudent, isFree }) {
  const loginNewUser = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // go to home page or onboarding
    login({ token, path: routeCodes.HOME });
  };

  const renderAccessUrl = () => {
    if (isComplete && isStudent) {
      const accessUrl = `${window.location.origin}${removeTrailingSlash(routeCodes.LOGIN)}?${getSearchParamString(
        "name",
      )}=${accessName}&${getSearchParamString("code")}=${accessCode}`;

      return getString("subscribe.complete.thankyou.autologin", { replace: [accessUrl], html: true });
    }

    return null;
  };

  const message = isComplete
    ? getString("subscribe.complete.thankyou.message")
    : getString("subscribe.thankyou.message");

  return (
    <>
      <Modal doShow={!isFree && token} closeOnEscape={loginNewUser}>
        <div className="modal-content-header">{getString("subscribe.thankyou.title")}</div>
        <form onSubmit={loginNewUser}>
          <p>{message}</p>
          {renderAccessUrl()}
          <button type="submit" className="button-flat-color pt-green">
            {getString("subscribe.thankyou.submit")}
          </button>
        </form>
      </Modal>
    </>
  );
}

SubscribedModal.propTypes = {
  token: PropTypes.string,
  accessName: PropTypes.string,
  accessCode: PropTypes.string,
  isComplete: PropTypes.bool,
  isStudent: PropTypes.bool,
  isFree: PropTypes.bool,
};
