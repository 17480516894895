import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { osName, osVersion, browserName, browserVersion } from "react-device-detect";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useMutation } from "urql";
import { CONTACT_US_MUTATION } from "../../graphql/mutations";
import { emailReg, getMaxLengthValidator } from "../../utilities/forms/rules";
import { getString, handlePostRequest, isLoggedInWithRole, sortedArrayByItem } from "../../utilities";
import { getAllGames } from "../../utilities/games";
import {
  userRoles,
  recaptchaSiteKey,
  EMAIL_CHAR_LIMIT,
  TEXT_FIELD_CHAR_LIMIT,
  getLangCode,
  language,
} from "../../config";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import TextViewHook from "../global/textViewHook";
import ErrorMessage from "../global/errorMessage";
import LoadingAnim from "../global/loadingAnim";
import OKModal from "../global/okModal";
import Select from "../global/Select";
import { isLoggedInSelector } from "../../redux/slices/loginSlice";
import { adBlockingSelector } from "../../redux/slices/userClientSlice";
import { dataSelector, loadingSelector } from "../../redux/slices/dataSlice";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function ContactUs({ gameData, setGameData }) {
  const dataLoading = useSelector(loadingSelector);
  const data = useSelector(dataSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const adBlocking = useSelector(adBlockingSelector);

  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const [games, setGames] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (!dataLoading && data.games) {
      let allGames = getAllGames(data.games);
      allGames = sortedArrayByItem(
        allGames.map((g) => ({ label: g.fullname, value: g.id })),
        "label",
      );
      allGames.unshift({ label: getString("none.0"), value: "" });

      setGames(allGames);
    }
  }, [data, dataLoading]);

  const handleGameSelect = (selectedOption) => {
    if (gameData?.value !== selectedOption.value) {
      setGameData(selectedOption);
    }
  };

  const getVariables = () => {
    const { message, email } = getValues();
    const myVariables = {
      email,
      body: message,
      envOsName: osName,
      envOsVer: osVersion,
      envBrowserName: browserName,
      envBrowserVer: browserVersion,
      gameId: gameData?.value,
      adBlock: adBlocking,
    };

    return myVariables;
  };

  const onContactCompleted = () => {
    setGameData(null);
    setSubmitting(false);
    setSuccessModal(true);
    setSubmitErrors(null);
    reset({ email: "", message: "" });
  };

  const onContactError = (error) => {
    setSubmitErrors({ message: error.message });
    setSubmitting(false);
    reset({ email: "", message: "" });
  };

  const [contactResult, contactUs] = useMutation(CONTACT_US_MUTATION);
  const { fetching: loading } = contactResult;

  const onCompleted = async ({ reCaptchaVerify }) => {
    if (reCaptchaVerify) {
      if (reCaptchaVerify.success) {
        // verified
        // execute forgot mutation
        const variables = await getVariables();

        if (variables) {
          contactUs(variables).then((result) => handlePostRequest(result, onContactCompleted, onContactError));
        }
      } else {
        // not verified
        setSubmitting(false);
        setSubmitErrors({ message: reCaptchaVerify.message });
      }
    }
  };

  const onError = (error) => {
    setSubmitting(false);
    setSubmitErrors({ message: error.message });
  };

  const action = "Contact_Us_Form_Submission";
  const { executeQuery: recaptchaVerify } = useLazyQuery(RECAPTCHA_VERIFY_QUERY, onCompleted, onError);

  const renderGameSelect = () => {
    if (dataLoading) {
      return <LoadingAnim />;
    }
    if (!submitting && games && games.length > 0) {
      return (
        <Select
          onChange={handleGameSelect}
          items={games}
          searchKey="label"
          label={getString("faq.feedback.game")}
          defaultValue={gameData}
        />
      );
    }

    return null;
  };

  if (isLoggedInWithRole(userRoles.student)) {
    return null;
  }

  return (
    <>
      <div className="wrapper visible padded odd">
        <form
          className="faq-contactus-form"
          onSubmit={handleSubmit(async () => {
            setSubmitting(true);

            // perform reCaptcha to get token
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
                recaptchaVerify({ token, action, verify: false });
              });
            });
          })}
        >
          <FormProvider register={register}>
            <div className="container-medium">
              <OptionallyDisplayed doDisplay={!!submitErrors}>
                <ErrorMessage error={submitErrors} />
              </OptionallyDisplayed>

              <OptionallyDisplayed doDisplay={!isLoggedIn}>
                <TextViewHook
                  name="email"
                  errors={errors}
                  placeholder={getString(getLangCode() === language.la ? "forms.email.2" : "forms.email.0")}
                  rules={{
                    required: getString("forms.errorMessages.isRequired.0", {
                      replace: [getString(getLangCode() === language.la ? "forms.email.6" : "forms.email.2")],
                    }),
                    pattern: {
                      value: emailReg,
                      message: getString("forms.errorMessages.email.0", {
                        replace: [getString(getLangCode() === language.la ? "forms.email.6" : "forms.email.0")],
                      }),
                    },
                    maxLength: getMaxLengthValidator(getString("forms.email.3"), EMAIL_CHAR_LIMIT),
                  }}
                />
              </OptionallyDisplayed>

              <TextViewHook
                name="message"
                type="textarea"
                errors={errors}
                rules={{
                  required: getString(
                    getLangCode() === language.la
                      ? "forms.errorMessages.isRequired.1"
                      : "forms.errorMessages.isRequired.0",
                    {
                      replace: [
                        getString(getLangCode() === language.la ? "faq.feedback.title.2" : "faq.feedback.title.1"),
                      ],
                    },
                  ),
                  maxLength: getMaxLengthValidator(getString("faq.feedback.title.1"), TEXT_FIELD_CHAR_LIMIT),
                }}
                dataTestId="message-textarea"
              />

              {renderGameSelect()}

              <div className="faq-contactus-form-footer">
                <p>{getString("faq.feedback.cta")}</p>

                <button
                  type="submit"
                  disabled={loading || submitting}
                  className="button-flat-color pt-green manage-update-button"
                >
                  {getString("send.0")}
                </button>

                <p>{getString("faq.feedback.terms", { html: true })}</p>

                <p>{getString("recaptcha", { html: true })}</p>
              </div>
            </div>
          </FormProvider>
        </form>

        {(loading || submitting) && (
          <LoadingAnim position="absolute" className="background-transparent-white">
            <h4>{getString("send.1")}</h4>
          </LoadingAnim>
        )}
      </div>

      <OKModal
        doShow={successModal}
        close={async () => {
          const messageInput = document.getElementById("message");
          messageInput.value = "";
          setSuccessModal(false);
        }}
        title={getString("faq.feedback.success.title")}
      >
        <p>{isLoggedIn ? getString("faq.feedback.success.message.1") : getString("faq.feedback.success.message.0")}</p>
      </OKModal>
    </>
  );
}

ContactUs.propTypes = {
  gameData: PropTypes.object,
  setGameData: PropTypes.func.isRequired,
};

ContactUs.defaultProps = {
  gameData: undefined,
};
