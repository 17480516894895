import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getString } from "../../utilities";
import { gtmJoinClick } from "../../utilities/gtm";
import { mobile } from "../../utilities/detection";
import { routeCodes } from "../../config/routes";
import { getLangCode } from "../../config";

export default function AdFree(props) {
  const { doDisplay, path } = props;
  const navigate = useNavigate();

  if (doDisplay && !mobile) {
    return (
      <div className="gv-title-buttons-container">
        <button
          type="button"
          className={`button-dimensional-icon adfree ${getLangCode()}`}
          onClick={() => {
            gtmJoinClick(path, "game-button");
            navigate(routeCodes.SUBSCRIBE);
          }}
        >
          <span>{getString("ads.go")}</span>
        </button>
      </div>
    );
  }

  return null;
}

AdFree.propTypes = {
  doDisplay: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

AdFree.defaultProps = {
  doDisplay: undefined,
};
