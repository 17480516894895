import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation, useOutletContext } from "react-router-dom";

import { metaDefault } from "../../config/meta";
import { getLangCode, language, recaptchaSiteKey, userRoles } from "../../config";
import { SIGNUP_MUTATION } from "../../graphql/mutations";
import { RECAPTCHA_VERIFY_QUERY, SUBSCRIPTIONS_PLAN_QUERY } from "../../graphql/queries";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import LoadingAnim from "../global/loadingAnim";
import ErrorMessage from "../global/errorMessage";
import { getString, handlePostRequest, login } from "../../utilities";
import { gtmSubscribeNow } from "../../utilities/gtm";
import AgeGateModal from "./ageGateModal";
import AccountFields from "./accountFields";
import { submitError } from "../../utilities/subscribe";
import { useLazyQuery, useQueryHandlers } from "../../hooks";
import { useMutation, useQuery } from "urql";
import routeCodes from "../../config/routeCodes";

export default function FreeForm() {
  const action = `FREE_User_Form_Submission`;
  const [plan, setPlan] = useState({});
  const [ageGateModal, setAgeGateModal] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);

  const { success, submitting, setSubmitting } = useOutletContext();

  const location = useLocation();

  const {
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const getVariables = () => {
    const { email, emailVerify, password, passwordVerify } = getValues();

    const myVariables = {
      email: email.trim(),
      emailVerify: emailVerify.trim(),
      password,
      passwordVerify,
      billingPlan: plan.planId,
    };

    return myVariables;
  };

  const { name, planId, amount } = plan;

  const onSubCompleted = (data) => {
    if (data && data.subscriptions && data.subscriptions.length > 0) {
      setPlan(data.subscriptions[0]);
    }
  };

  const [subResult] = useQuery({
    query: SUBSCRIPTIONS_PLAN_QUERY,
    variables: { planGroup: userRoles.free },
  });
  const { fetching: subLoading, error: subError } = subResult;
  useQueryHandlers(subResult, onSubCompleted);

  const onSignupCompleted = (data) => {
    const token = success(data, { planId, amount, name });
    login({ token, path: routeCodes.ONBOARDING });
  };

  const onSignupError = (error) => {
    setSubmitting(false);
    setSubmitErrors(error);
  };

  const [signupResult, signup] = useMutation(SIGNUP_MUTATION);
  const { fetching: loading } = signupResult;

  const onRecaptchaCompleted = async (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      await gtmSubscribeNow({ planId, amount, name });
      signup(getVariables()).then((result) => handlePostRequest(result, onSignupCompleted, onSignupError));
    } else {
      // not verified
      setSubmitting(false);
      setSubmitErrors({ message: reCaptchaVerify.message });
    }
  };

  const onRecaptchaError = (error) => {
    submitError(error, setSubmitErrors, setSubmitting);
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onRecaptchaError,
  );

  if (subLoading) {
    return <LoadingAnim />;
  }

  if (subError) {
    return <ErrorMessage error={subError} />;
  }

  return (
    <>
      {metaDefault({
        path: location.pathname,
        title: `${getString("join.3")} • ${getString("subscribe.plans.free.title.1")}`,
      })}
      <div className="wrapper padded odd">
        <div className="container-small">
          <form
            onSubmit={handleSubmit(() => {
              if (!submitting) {
                setAgeGateModal((prev) => !prev);
              }
            })}
          >
            <OptionallyDisplayed doDisplay={!!submitErrors}>
              <ErrorMessage error={submitErrors} />
            </OptionallyDisplayed>

            <h2>{getString("subscribe.create")}</h2>

            <AccountFields errors={errors} submitErrors={submitErrors} setSubmitErrors={setSubmitErrors} />

            <button type="submit" className="button-flat-color pt-green" disabled={submitting}>
              {getString("subscribe.submit")}
            </button>
          </form>

          <div className="subscribe-terms">
            <p>
              {getString("subscribe.terms", {
                replace: [
                  getLangCode() === language.la ? getString("subscribe.title.1") : getString("subscribe.title.0"),
                ],
                html: true,
              })}
            </p>
            <p>{getString("recaptcha", { html: true })}</p>
          </div>
        </div>
      </div>
      <AgeGateModal
        showAgeGateModal={ageGateModal}
        setAgeGateModal={setAgeGateModal}
        submit={() => {
          setSubmitting(true);

          // perform reCaptcha to get token
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
              recaptchaVerify({ token, action, verify: true });
            });
          });
        }}
      />
      {(loading || recaptchaLoading || submitting) && (
        <LoadingAnim position="fixed" className="background-transparent-white">
          <h4>{getString("subscribe.loading")}</h4>
        </LoadingAnim>
      )}
    </>
  );
}
