import React from "react";
import PropTypes from "prop-types";
import Arrow from "../../../assets/svg/arrowRight.svg";
import { isAFunction } from "../../utilities";
import OptionallyDisplayed from "./optionallyDisplayed";

export default function BillboardArrow({ clickHandler, className }) {
  const hasClick = clickHandler && isAFunction(clickHandler);

  const nextClickHandler = hasClick ? clickHandler : () => {};

  return (
    <div className={`billboard-arrow ${className}`} onClick={nextClickHandler}>
      <div className="billboard-arrow-button">
        <OptionallyDisplayed doDisplay={hasClick}>
          <Arrow />
        </OptionallyDisplayed>
      </div>
    </div>
  );
}

BillboardArrow.propTypes = {
  clickHandler: PropTypes.func,
  className: PropTypes.string,
};

BillboardArrow.defaultProps = {
  className: "",
  clickHandler: undefined,
};
