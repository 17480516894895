import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "urql";

import { MANAGE_ACCOUNT_MUTATION } from "../../graphql/mutations";
import { getString, handlePostRequest } from "../../utilities";
import {
  EMAIL_CHAR_LIMIT,
  getLangCode,
  language,
  PASSWORD_CHAR_LIMIT,
  PASSWORD_CHAR_MINIMUM,
  recaptchaSiteKey,
  tokenName,
} from "../../config";
import OptionallyDisplayed from "../global/optionallyDisplayed";
import TextViewHook from "../global/textViewHook";
import ErrorMessage from "../global/errorMessage";
import LoadingAnim from "../global/loadingAnim";
import PasswordConfirmModal from "../global/passwordConfirmModal";
import StudentFields from "../global/studentFields";
import { getMaxLengthValidator, validateEmail } from "../../utilities/forms/rules";
import { emailSelector, planIdSelector } from "../../redux/slices/loginSlice";
import { useLazyQuery } from "../../hooks";
import { RECAPTCHA_VERIFY_QUERY } from "../../graphql/queries";

export default function AccountInfo({ isUpdating, setIsUpdating, success, studentAccess }) {
  const action = `Change_Account_Info_Form_Submission`;
  const [submitErrors, setSubmitErrors] = useState(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const loginEmail = useSelector(emailSelector);
  const planId = useSelector(planIdSelector);

  const langCode = getLangCode();

  const { formState, getValues, register, reset, trigger } = useForm({
    mode: "onChange",
    // Default values need to be specified so that react hook form knows that fields are not dirty when empty.
    defaultValues: {
      email: "",
      emailVerify: "",
      password: "",
      passwordVerify: "",
      ...(!!studentAccess && { accessName: "", accessCode: "" }),
    },
  });

  const onChange = () => {
    if (submitErrors) {
      setSubmitErrors(null);
    }
  };

  // This is necessary to detect changes in formState in below useEffect: https://react-hook-form.com/api/useform/formstate
  const { dirtyFields, isValidating, isValid } = formState;

  useEffect(() => {
    const values = getValues();
    setCanUpdate(
      Object.keys(values).some((field) => values[field].length > 0) &&
        (!formState.errors || Object.keys(formState.errors).length === 0) &&
        !isUpdating,
    );
  }, [formState]);

  const getVariables = () => {
    const { email, emailVerify, password, passwordVerify, accessCode, accessName } = getValues();

    const myVariables = {
      ...(email.length > 0 && { email }),
      ...(emailVerify.length > 0 && { emailVerify }),
      ...(password.length > 0 && { password }),
      ...(passwordVerify.length > 0 && { passwordVerify }),
      ...(accessName?.length > 0 && { accessName }),
      ...(accessCode?.length > 0 && { accessCode }),
    };

    return myVariables;
  };

  const onError = (error) => {
    setIsUpdating(false);
    setSubmitErrors(error);
  };

  const onUpdateCompleted = (data) => {
    reset();
    const { token } = data.updateUser;

    try {
      window.localStorage.setItem(tokenName, token);
    } catch (e) {}
    success({
      message: <p>{getString("manage.accountInfo.success")}</p>,
      token,
    });
  };

  const [result, updateUser] = useMutation(MANAGE_ACCOUNT_MUTATION);
  const { fetching: loading } = result;

  const onRecaptchaCompleted = (data) => {
    const { reCaptchaVerify } = data;

    if (reCaptchaVerify.success) {
      // verified
      updateUser(getVariables()).then((result) => handlePostRequest(result, onUpdateCompleted, onError));
    } else {
      // not verified
      setIsUpdating(false);
      setSubmitErrors({ message: reCaptchaVerify.message });
    }
  };

  const { executeQuery: recaptchaVerify, loading: recaptchaLoading } = useLazyQuery(
    RECAPTCHA_VERIFY_QUERY,
    onRecaptchaCompleted,
    onError,
  );

  const renderCurrent = (item) => {
    return (
      <div className="form-field-children">
        <div className="label">{getString("manage.accountInfo.current")}</div>
        <div className="current-value">{item}</div>
      </div>
    );
  };

  const isIxlSchoolUser = planId === "ixl_school";

  const renderEmail = () => {
    if (isIxlSchoolUser) {
      return (
        <p className="form-field">
          {getString("forms.email.0")}: &nbsp;
          <strong>{loginEmail}</strong>
        </p>
      );
    }
    return (
      <>
        <div className="col1">{renderCurrent(loginEmail)}</div>

        <TextViewHook
          rules={{
            validate: (value) =>
              !value || validateEmail(value, getString(langCode === language.la ? "forms.email.7" : "forms.email.4")),
            maxLength: getMaxLengthValidator(
              getString(langCode === language.la ? "forms.email.7" : "forms.email.4"),
              EMAIL_CHAR_LIMIT,
            ),
          }}
          errors={formState.errors}
          className={langCode === language.la ? "col1" : "col2"}
          name="email"
          placeholder={getString("forms.email.4")}
          label={getString("forms.email.4")}
          onChange={() => {
            trigger("emailVerify");
            onChange();
          }}
        />

        <TextViewHook
          rules={{
            validate: (value) =>
              value === getValues().email ||
              getString(
                langCode === language.la ? "forms.errorMessages.mustMatch.1" : "forms.errorMessages.mustMatch.0",
                { replace: [getString(langCode === language.la ? "forms.email.5" : "forms.email.4")] },
              ),
          }}
          errors={formState.errors}
          className={langCode === language.la ? "col1" : "col2 right"}
          name="emailVerify"
          placeholder={getString("forms.adultEmailVerify")}
          label={getString("forms.adultEmailVerify")}
          onChange={() => {
            trigger("email");
            onChange();
          }}
        />
      </>
    );
  };

  return (
    <div id="account" className="wrapper padded">
      <h2>{getString("manage.accountInfo.title.1")}</h2>
      <>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setPasswordModal((prev) => !prev);
          }}
        >
          <FormProvider register={register} formState={formState}>
            <div className="container-small">
              <OptionallyDisplayed doDisplay={!!submitErrors}>
                <ErrorMessage error={submitErrors} />
              </OptionallyDisplayed>

              {renderEmail()}
              <OptionallyDisplayed doDisplay={isIxlSchoolUser}>
                <p>{getString("manage.accountInfo.ixlSchoolContactCta", { html: true })}</p>
              </OptionallyDisplayed>

              <TextViewHook
                rules={{
                  maxLength: getMaxLengthValidator(
                    getString(langCode === language.la ? "forms.newPassword.3" : "forms.newPassword.0"),
                    PASSWORD_CHAR_LIMIT,
                  ),
                  validate: (value) =>
                    value.length === 0 ||
                    value.length >= PASSWORD_CHAR_MINIMUM ||
                    getString("forms.errorMessages.isTooShort", {
                      replace: [
                        getString(langCode === language.la ? "forms.newPassword.3" : "forms.newPassword.0"),
                        PASSWORD_CHAR_MINIMUM,
                      ],
                    }),
                }}
                errors={formState.errors}
                className={langCode === language.la ? "col1" : "col2"}
                name="password"
                type="password"
                placeholder={getString("forms.newPassword.0")}
                label={getString("forms.newPassword.0")}
                onChange={() => {
                  trigger("passwordVerify");
                  onChange();
                }}
              />

              <TextViewHook
                rules={{
                  validate: (value) =>
                    value === getValues().password ||
                    getString(
                      langCode === language.la ? "forms.errorMessages.mustMatch.1" : "forms.errorMessages.mustMatch.0",
                      {
                        replace: [getString(langCode === language.la ? "forms.newPassword.2" : "forms.newPassword.0")],
                      },
                    ),
                }}
                errors={formState.errors}
                className={langCode === language.la ? "col1" : "col2 right"}
                name="passwordVerify"
                type="password"
                placeholder={getString("forms.newPasswordVerify")}
                label={getString("forms.newPasswordVerify")}
                onChange={onChange}
              />

              <OptionallyDisplayed doDisplay={!!studentAccess}>
                <StudentFields
                  studentAccess={studentAccess}
                  submitErrors={submitErrors}
                  setSubmitErrors={setSubmitErrors}
                />
              </OptionallyDisplayed>

              <div className="manage-form-footer">
                <button type="submit" className="button-flat-color pt-green manage-update-button" disabled={!canUpdate}>
                  {getString("update.0")}
                </button>
              </div>
            </div>
          </FormProvider>
        </form>

        <PasswordConfirmModal
          doShow={passwordModal}
          close={() => {
            setPasswordModal((prev) => !prev);
            setIsUpdating(false);
          }}
          submit={() => {
            setIsUpdating(true);
            setPasswordModal((prev) => !prev);

            // perform reCaptcha to get token
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(recaptchaSiteKey, { action }).then((token) => {
                recaptchaVerify({ token, action, verify: false });
              });
            });
          }}
        >
          <p>{getString("manage.password")}</p>
        </PasswordConfirmModal>

        {loading ||
          (recaptchaLoading && (
            <LoadingAnim position="absolute" className="background-transparent-pt-grey">
              <h4>{getString("update.1")}</h4>
            </LoadingAnim>
          ))}
      </>
    </div>
  );
}

AccountInfo.propTypes = {
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
  success: PropTypes.func.isRequired,
  studentAccess: PropTypes.object,
};

AccountInfo.defaultProps = {
  studentAccess: undefined,
};
